import "./App.css";
import Layout from "./Pages/Layout";
import { auth, firestore } from "./firebase";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>Foto Kiosco</title>
      </Helmet>
      <Layout></Layout>
    </>
  );
}

export default App;
